import React, { useEffect, useState } from 'react';
import cookie from 'cookie';
import CookieTestResultsTable from './cookie-test-results-table';

const HTTPCookieTests = ({ host, tests, check, checkDelays }) => {
  const [testResults, setTestResults] = useState(new Array(tests.length));

  // Sets cookies on the backend
  const setCookies = async () => {
    try {
      const requests = tests.map(test => {
        const searchParams = new URLSearchParams(test.query);
        searchParams.append('name', test.cookie);

        return fetch(`https://${host}/backend/set-cookie?${searchParams.toString()}`, {
          credentials: 'include',
        });
      });

      await Promise.all(requests);
    } catch (error) {
      console.error(error);
    }
  };

  // Gets cookies from the backend
  const getCookies = async (url) => {
    try {
      const response = await fetch(url, { credentials: 'include' });
      const { cookies } = await response.json();
      return cookie.parse(cookies);
    } catch (error) {
      console.error(error);
      return {};
    }
  };

  // Runs the tests and updates the test results
  const runTest = async () => {
    await setCookies();

    const delays = Array.isArray(checkDelays) && checkDelays.length > 0 ? checkDelays : [0];

    for (const [index, duration] of delays.entries()) {
      setTimeout(async () => {
        const cookies = await getCookies(`https://${host}/backend/get-cookie`);
        const results = check(cookies, tests, index);
        setTestResults(results);
      }, duration);
    }
  };

  useEffect(() => {
    runTest();
  }, []);

  return (
    <CookieTestResultsTable
      tests={tests.map((test, idx) => ({
        ...test,
        passed: testResults[idx],
      }))}
    />
  );
};

export default HTTPCookieTests;
