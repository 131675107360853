import React, { useState } from 'react';
import { Link } from 'gatsby';
import Seo from '../../components/seo';
import Layout from '../../components/layout';
import HTTPCookieTests from '../../components/http-cookie-tests';
import JSCookieTests from '../../components/js-cookie-test';
import { getRandomString } from '../../utils/utils';


const getProgressIndicator = (checkNr, currentCheckNr) => {
  const isDone = currentCheckNr >= checkNr;
  return <span className={isDone ? '' : 'animate-ping'}>
    {isDone ? '✅' : '⏳'}
  </span>;
}

// cookies that will be set with the Set-Cookie headers, 1st and 3rd parties
const tests = [
  { name: 'session', cookie: getRandomString(), query: '' },
  { name: 'under-1m-expires', cookie: getRandomString(), query: 'exp_attr=expires&exp_sec=30' },
  { name: 'under-1m-max-age', cookie: getRandomString(), query: 'exp_attr=max-age&exp_sec=20' },
  { name: 'over-1m-expires', cookie: getRandomString(), query: 'exp_attr=expires&exp_sec=500' },
  { name: 'over-1m-max-age', cookie: getRandomString(), query: 'exp_attr=max-age&exp_sec=300' },
];
// cookies that will be set with js api in a 3rd party iframe
const iframeTests = [
  { name: 'iframe-session-cookie', cookie: getRandomString(), props: { api: 'document.cookie' } },
  { name: 'iframe-under-1m-expires', cookie: getRandomString(), props: { api: 'document.cookie', exp_attr: 'expires', exp_sec: 30 } },
  { name: 'iframe-under-1m-max-age', cookie: getRandomString(), props: { api: 'document.cookie', exp_attr: 'max-age', exp_sec: 20 } },
  { name: 'iframe-under-1m-cookie-store', cookie: getRandomString(), props: { api: 'CookieStore', exp_sec: 30 } },
  { name: 'iframe-over-1m', cookie: getRandomString(), props: { api: 'CookieStore', exp_sec: 300 } },
];

const CookieExpirationPage = () => {
  const [checks, setChecks] = useState(0);

  const check3rdParty = (cookies, tests, checkNr) => {
    if (checkNr === 0) {
      return tests.map((t) => (cookies[t.cookie] !== undefined));
    }

    if (checkNr === 1) {
      return tests.map((t) => {
        if (t.name.includes('under-1m')) {
          return cookies[t.cookie] === undefined;
        }
        return cookies[t.cookie] !== undefined;
      });
    }

    if (checkNr === 2) {
      return tests.map((t) => {
        if (t.name.includes('session')) {
          return cookies[t.cookie] !== undefined;
        }
        return cookies[t.cookie] === undefined;
      });
    }
  };

  const check1stParty = (cookies, tests, checkNr) => {
    setChecks(checkNr + 1);
    if (checkNr === 0) {
      return tests.map((t) => (cookies[t.cookie] !== undefined));
    }

    if (checkNr === 1 || checkNr === 2) {
      return tests.map((t) => {
        if (t.name.includes('under-1m')) {
          return cookies[t.cookie] === undefined;
        }
        return cookies[t.cookie] !== undefined;
      });
    }
  };

  return (
    <Layout>
      <h1>Cookie expiration</h1>

      <p>
        3rd party Cookies that have an expiration time greater than 1 minute, will
        be deleted automatically after 1 minute.
      </p>

      <button
        className="bg-emerald-600 text-white w-full mb-10 mt-5"
        disabled={checks > 0 && checks < 3}
        onClick={() => {
          window.location.reload();
        }}
      >
        Run test
      </button>

      <div className="font-bold">Checks:</div>
      <ul className="list-disc">
        <li>Check after 0 seconds {getProgressIndicator(1, checks)}</li>
        <li>Check after 45 seconds {getProgressIndicator(2, checks)}</li>
        <li>Check after 120 seconds {getProgressIndicator(3, checks)}</li>
      </ul>

      <h3>1st party cookies</h3>
      <HTTPCookieTests
        host={process.env.GATSBY_MAIN_HOST}
        tests={tests}
        checkDelays={[0, 45000, 120000]}
        check={check1stParty}
      />

      <h3>3rd party cookies</h3>
      <HTTPCookieTests
        host={process.env.GATSBY_SECONDARY_HOST}
        tests={tests}
        checkDelays={[0, 45000, 120000]}
        check={check3rdParty}
      />

      <h3>1st party JS cookies</h3>
      <JSCookieTests
        host={process.env.GATSBY_MAIN_HOST}
        tests={iframeTests}
        checkDelays={[0, 45000, 120000]}
        check={check1stParty}
      />

      <h3>3rd party JS cookies</h3>
      <JSCookieTests
        host={process.env.GATSBY_SECONDARY_HOST}
        tests={iframeTests}
        checkDelays={[0, 45000, 120000]}
        check={check3rdParty}
      />

      <Link to="/">
        Go back
      </Link>
    </Layout>
  );
}

export const Head = () => <Seo title="Removed headers" />
export default CookieExpirationPage;
