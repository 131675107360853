export function getRandomString() {
  return Math.random().toString(32).substring(2, 7);
};


export async function getBlockedImageURL() {
  const response = await fetch('/backend/get-easyprivacy-list');
  const text = await response.text();
  // get first filter which is a valid path
  for (let line of text.split('\n')) {
    if (line.startsWith('/') && !line.includes('*') && !line.includes('$')) {
      return `/request-blocked${line}`;
    }
  }

  return `/img/crumbs-img.jpg`;
}
